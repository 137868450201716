
import { defineComponent } from 'vue';
import * as Yup from 'yup';
import ApiService from '@/core/services/ApiService';
import Swal from 'sweetalert2/dist/sweetalert2.min.js';
import { apiEndpoint } from '@/mixin/apiEndpoint';
import { VueCookieNext } from 'vue-cookie-next';
import moment from 'moment';
export default defineComponent({
    mixins: [apiEndpoint],
    name: 'search-trainee',
    components: {
        //Datatable,
    },
    data() {
        return {
            api_url: '',
            moment: '' as any,
            trainee: {
                registration_number: '',
            },
            entity_id: '' as any,
            training_institute_id: '' as any,
            course_info_id: '' as any,
            batch_info_id: '' as any,
            traineeInfo: {
                id: '',
                entity_id: '',
                training_institute_id: '',
                course_info_id: '',
                batch_info_id: '',
            },
            load: false,
            showTraineeInfo: false,
        };
    },
    async created() {
        this.moment = moment;
        this.api_url = this.VUE_APP_API_URL;
    },
    methods: {
        async getTraineeInfo() {
            if (
                this.trainee.registration_number
            ) {
                this.load = true;
                await ApiService.get(
                    'trainee/search?registration_number=' +
                    this.trainee.registration_number

                )
                    .then((response) => {

                        this.load = false;
                        this.traineeInfo = response.data.data;
                        if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
                            let entity_id = VueCookieNext.getCookie('_entity_id');
                            entity_id = parseInt(entity_id);
                            console.log(this.traineeInfo.entity_id, entity_id);
                            if (this.traineeInfo.entity_id == entity_id) {
                                // ApiService.get('training-certificate-view' + this.traineeInfo.id)
                                const url = `https://tms.seip-fd.gov.bd/certificate/training-certificate-view/${this.traineeInfo.id}`;
                                window.open(url, '_blank');
                                // this.entity_id = this.traineeInfo.entity_id;
                                // this.training_institute_id = this.traineeInfo.training_institute_id;
                                // this.course_info_id = this.traineeInfo.course_info_id;
                                // this.batch_info_id = this.traineeInfo.batch_info_id;
                                // this.showTraineeInfo = true;
                            }
                            else {
                                this.showTraineeInfo = false;
                                Swal.fire({
                                    title: 'Error!',
                                    text: 'Trainee not found!',
                                    icon: 'error',
                                    buttonsStyling: false,
                                    confirmButtonText: 'Close',
                                    customClass: {
                                        confirmButton: 'btn btn-danger',
                                    },

                                }).then((response) => {
                                    location.reload();
                                })
                                    .catch(({ response }) => {

                                        console.log(response);
                                    });
                            }
                        }
                        else {
                            const url = `https://tms.seip-fd.gov.bd/certificate/training-certificate-view/${this.traineeInfo.id}`;
                            window.open(url, '_blank');
                        }

                    })
                    .catch(({ response }) => {
                        console.log(response);
                    });
            } else {
                Swal.fire({
                    title: 'Error!',
                    text: 'Please give input first!',
                    icon: 'error',
                    buttonsStyling: false,
                    confirmButtonText: 'Close',
                    customClass: {
                        confirmButton: 'btn btn-danger',
                    },
                });
            }
        },
    },
});
